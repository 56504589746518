import React from 'react'
import image12 from '../assets/images/client/05.jpg';
import image13 from '../assets/images/blog/06.jpg';
import image14 from '../assets/images/blog/07.jpg';
import image15 from '../assets/images/blog/08.jpg';
import { Facebook, Github, Gitlab, Instagram, Linkedin, Twitter, Youtube } from 'feather-icons-react/build/IconComponents';
import { Link } from 'react-router-dom';
export default function BlogUserDetail(props) {
    const RecentPostData = [
        {
            Image: image13,
            Class: 'flex items-center mt-8',
            Title: 'Consultant Business',
            Date: '1st May 2022'
        },
        {
            Image: image14,
            Class: 'flex items-center mt-4',
            Title: 'Grow Your Business',
            Date: '1st May 2022'
        },
        {
            Image: image15,
            Class: 'flex items-center mt-8',
            Title: 'Look On The Glorious Balance',
            Date: '1st May 2022'
        }
    ];
    const TagscloudItem = [
        {
            item: 'Business'
        },
        {
            item: 'Finance'
        },
        {
            item: 'Marketing'
        },
        {
            item: 'Fashion'
        },
        {
            item: 'Bride'
        },
        {
            item: 'Lifestyle'
        },
        {
            item: 'Travel'
        },
        {
            item: 'Beauty'
        },
        {
            item: 'Video'
        },
        {
            item: 'Audio'
        }
    ];
    return (
        <div className={props.className}>
            <div className="sticky top-20">
                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Author</h5>
                <div className="text-center mt-8">
                    <img src={image12} className="h-24 w-24 mx-auto rounded-full shadow mb-4" alt="" />

                    <Link className="text-lg font-semibold hover:text-indigo-600 transition-all duration-500 ease-in-out">Cristina Romsey</Link>
                    <p className="text-slate-400">Content Writer</p>
                </div>

                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Recent post</h5>


                {RecentPostData.map((data, index) => {
                    return(
                        <div className={data.Class} key={index}>
                            <img src={data.Image} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />
    
                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">{data.Title}</Link>
                                <p className="text-sm text-slate-400">{data.Date}</p>
                            </div>
                        </div>
                    )
                })}



                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Social sites</h5>
                <ul className="list-none text-center mt-8 space-x-1">
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Facebook className="h-4 w-4"></Facebook></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Instagram className="h-4 w-4"></Instagram></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Twitter className="h-4 w-4"></Twitter></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Linkedin className="h-4 w-4"></Linkedin></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Github className="h-4 w-4"></Github></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Youtube className="h-4 w-4"></Youtube></Link></li>
                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Gitlab className="h-4 w-4"></Gitlab></Link></li>
                </ul>

                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Tagscloud</h5>
                <ul className="list-none text-center mt-8">
                    {TagscloudItem.map((data,index) => {
                        return(
                            <li className="inline-block m-2" key={index}><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">{data.item}</Link></li>
                        )
                    }
                        
                    )}
                </ul>
            </div>
        </div>
    )
}
