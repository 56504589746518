import React from 'react'
import { Link } from 'react-router-dom'

import Envelop from "../../assets/images/illustrator/envelope.svg" 
import App from "../../assets/images/app/app.png"
import PlayStore from "../../assets/images/app/playstore.png"

import ShopFooter from '../../component/Footer/shopFooter'
import EcommerceNavbar from '../../component/Navbar/ecommerceNavbar'

import {  Smartphone } from 'feather-icons-react/build/IconComponents'

export default function shopCart() {
    const handleStepDown = (e) => {
        const input = e.target.parentNode.querySelector('input[type="number"]')
        if (input) {
            input.stepDown();
        }
    };

    const handleStepUp = (e) => {
        const input = e.target.parentNode.querySelector('input[type="number"]')
        if (input) {
            input.stepUp();
        }
    };

    return (
        <>
            <EcommerceNavbar />
            <section className="relative table w-full py-20 lg:py-24 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-14">
                        <h3 className="text-3xl leading-normal font-semibold">Cart</h3>
                    </div>

                    <div className="relative mt-3">
                        <ul className="tracking-[0.5px] mb-0 inline-block space-x-1">
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600"><Link to="/index-shop">Techwind</Link></li>
                            <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5"><i className="uil uil-angle-right-b"></i></li>
                            <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-indigo-600" aria-current="page">Cart</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid lg:grid-cols-1">
                        <div className="relative overflow-x-auto shadow dark:shadow-gray-800 rounded-md">
                            <table className="w-full text-start">
                                <thead className="text-sm uppercase bg-slate-50 dark:bg-slate-800">
                                    <tr>
                                        <th scope="col" className="p-4 w-4"></th>
                                        <th scope="col" className="text-start p-4 min-w-[220px]">Product</th>
                                        <th scope="col" className="p-4 w-24 min-w-[100px]">Price</th>
                                        <th scope="col" className="p-4 w-56 min-w-[220px]">Qty</th>
                                        <th scope="col" className="p-4 w-24 min-w-[100px]">Total($)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="bg-white dark:bg-slate-900">
                                        <td className="p-4"><Link ><i className="mdi mdi-window-close text-red-600"></i></Link></td>
                                        <td className="p-4">
                                            <span className="flex items-center">
                                                <img src="../../assets/images/shop/items/s1.jpg" className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                <span className="ms-3">
                                                    <span className="block font-semibold">T-shirt (M)</span>
                                                </span>
                                            </span>
                                        </td>
                                        <td className="p-4 text-center">$ 280</td>
                                        <td className="p-4 text-center">
                                            <div className="qty-icons space-x-1">
                                                <button onClick={handleStepDown} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white minus"                                            >
                                                    -
                                                </button>
                                                <input min="0" readOnly name="quantity" value="0" type="number" className="h-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white pointer-events-none w-16 ps-4 quantity" />
                                                <button onClick={handleStepUp} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white plus" >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="p-4  text-end">$ 840</td>
                                    </tr>

                                    <tr className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-800">
                                        <td className="p-4"><Link ><i className="mdi mdi-window-close text-red-600"></i></Link></td>
                                        <td className="p-4">
                                            <span className="flex items-center">
                                                <img src="../../assets/images/shop/items/s2.jpg" className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                <span className="ms-3">
                                                    <span className="block font-semibold">Bag</span>
                                                </span>
                                            </span>
                                        </td>
                                        <td className="p-4 text-center">$ 160</td>
                                        <td className="p-4 text-center">
                                            <div className="qty-icons space-x-1">
                                                <button onClick={handleStepDown} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white minus"                                            >
                                                    -
                                                </button>
                                                <input min="0" readOnly name="quantity" value="0" type="number" className="h-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white pointer-events-none w-16 ps-4 quantity" />
                                                <button onClick={handleStepUp} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white plus" >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="p-4  text-end">$ 160</td>
                                    </tr>

                                    <tr className="bg-white dark:bg-slate-900 border-t border-gray-100 dark:border-gray-800">
                                        <td className="p-4"><Link ><i className="mdi mdi-window-close text-red-600"></i></Link></td>
                                        <td className="p-4">
                                            <span className="flex items-center">
                                                <img src="../../assets/images/shop/items/s3.jpg" className="rounded shadow dark:shadow-gray-800 w-12" alt="" />
                                                <span className="ms-3">
                                                    <span className="block font-semibold">Watch (Men)</span>
                                                </span>
                                            </span>
                                        </td>
                                        <td className="p-4 text-center">$ 500</td>
                                        <td className="p-4 text-center">
                                            <div className="qty-icons space-x-1">
                                                <button onClick={handleStepDown} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white minus"                                            >
                                                    -
                                                </button>
                                                <input min="0" readOnly name="quantity" value="0" type="number" className="h-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white pointer-events-none w-16 ps-4 quantity" />
                                                <button onClick={handleStepUp} className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white plus" >
                                                    +
                                                </button>
                                            </div>
                                        </td>
                                        <td className="p-4  text-end">$ 500</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                            <div className="lg:col-span-9 md:order-1 order-3">
                                <Link  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2">Shop Now</Link>
                                <Link  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center rounded-md bg-indigo-600/5 hover:bg-indigo-600 border-indigo-600/10 hover:border-indigo-600 text-indigo-600 hover:text-white mt-2">Add to Cart</Link>
                            </div>

                            <div className="lg:col-span-3 md:order-2 order-1">
                                <ul className="list-none shadow dark:shadow-gray-800 rounded-md">
                                    <li className="flex justify-between p-4">
                                        <span className="font-semibold text-lg">Subtotal :</span>
                                        <span className="text-slate-400">$ 1500</span>
                                    </li>
                                    <li className="flex justify-between p-4 border-t border-gray-100 dark:border-gray-800">
                                        <span className="font-semibold text-lg">Taxes :</span>
                                        <span className="text-slate-400">$ 150</span>
                                    </li>
                                    <li className="flex justify-between font-semibold p-4 border-t border-gray-200 dark:border-gray-600">
                                        <span className="font-semibold text-lg">Total :</span>
                                        <span className="font-semibold">$ 1650</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center">
                        <div className="lg:col-span-5 md:col-span-6">
                            <img src={Envelop} className="mx-auto d-block" alt="" />
                        </div>

                        <div className="lg:col-span-7 md:col-span-6">
                            <span className="bg-indigo-600/5 text-indigo-600 text-xs font-bold px-2.5 py-0.5 rounded h-5">Mobile Apps</span>
                            <h4 className="md:text-3xl text-2xl lg:leading-normal leading-normal font-medium my-4">Available for your <br /> Smartphones</h4>
                            <p className="text-slate-400 max-w-xl mb-0">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>
                            <div className="my-5">
                                <Link ><img src={App} className="m-1 inline-block" alt="" /></Link>

                                <Link ><img src={PlayStore} className="m-1 inline-block" alt="" /></Link>
                            </div>

                            <div className="inline-block">
                                <div className="pt-4 flex items-center border-t border-gray-100 dark:border-gray-800">
                                    <Smartphone className="me-2 text-indigo-600 h-10 w-10"></Smartphone>
                                    <div className="content">
                                        <h6 className="text-base font-medium">Install app now on your cellphones</h6>
                                        <Link  className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Learn More <i className="uil uil-angle-right-b"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

             <ShopFooter/>
        </>
    )
}
