import React from 'react'
import { Link } from 'react-router-dom';

import image from '../../assets/images/real/property/1.jpg';
import image1 from '../../assets/images/real/property/2.jpg';
import image2 from '../../assets/images/real/property/3.jpg';
import image3 from '../../assets/images/real/property/4.jpg';
import image4 from '../../assets/images/real/property/5.jpg';
import image5 from '../../assets/images/real/property/6.jpg';
import image6 from '../../assets/images/real/property/7.jpg';
import image7 from '../../assets/images/real/property/8.jpg';
import image8 from '../../assets/images/real/property/9.jpg';
import image9 from '../../assets/images/real/property/10.jpg';
import image10 from '../../assets/images/real/property/11.jpg';
import image11 from '../../assets/images/real/property/12.jpg';
import logo_light from '../../assets/images/logo-light.png';
import american_ex from '../../assets/images/payments/american-ex.png';
import discover from '../../assets/images/payments/discover.png';
import master_card from '../../assets/images/payments/master-card.png';
import paypal from '../../assets/images/payments/paypal.png';
import visa from '../../assets/images/payments/visa.png';

import Navbar from '../../component/Navbar/navbar'
import { Mail } from 'feather-icons-react/build/IconComponents';
import Select from 'react-select'

const project = [
    { value: '1', label: 'Houses' },
    { value: '2', label: 'Apartment' },
    { value: '3', label: 'Offices' },
    { value: '4', label: 'Townhome' },
]
const minPrice = [
    { value: '1', label: '500' },
    { value: '2', label: '1000' },
    { value: '3', label: '2000' },
    { value: '4', label: '3000' },
    { value: '5', label: '4000' },
    { value: '5', label: '5000' },
    { value: '5', label: '6000' },
    { value: '5', label: '7000' },
    { value: '5', label: '8000' },
    { value: '5', label: '9000' },
]
const maxPrice = [
    { value: '1', label: '500' },
    { value: '2', label: '1000' },
    { value: '3', label: '2000' },
    { value: '4', label: '3000' },
    { value: '5', label: '4000' },
    { value: '5', label: '5000' },
    { value: '5', label: '6000' },
    { value: '5', label: '7000' },
    { value: '5', label: '8000' },
    { value: '5', label: '9000' },
]
export default function PropertyListing() {
    const listing = [
        {
            image: image,
            address: '10765 Hillshire Ave, Baton Rouge, LA 70810, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image1,
            address: '59345 STONEWALL DR, Plaquemine, LA 70764, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image2,
            address: '3723 SANDBAR DR, Addis, LA 70710, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image3,
            address: 'Lot 21 ROYAL OAK DR, Prairieville, LA 70769, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image4,
            address: '710 BOYD DR, Unit #1102, Baton Rouge, LA 70808, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image5,
            address: '5133 MCLAIN WAY, Baton Rouge, LA 70809, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image6,
            address: '2141 Fiero Street, Baton Rouge, LA 70808',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image7,
            address: '9714 Inniswold Estates Ave, Baton Rouge, LA 70809',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image8,
            address: '1433 Beckenham Dr, Baton Rouge, LA 70808, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image9,
            address: '1574 Sharlo Ave, Baton Rouge, LA 70820, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image10,
            address: '2528 BOCAGE LAKE DR, Baton Rouge, LA 70809, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
        {
            image: image11,
            address: '1533 NICHOLSON DR, Baton Rouge, LA 70802, USA',
            sqrt: '8000sqf',
            beds: '4 Beds',
            bath: '4 Baths',
        },
    ]
    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-32 lg:py-36 bg-[url('../../assets/images/real/bg/01.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal leading-normal font-medium text-white">Grid View Layout</h3>
                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <div className="container relative -mt-16 z-1">
                <div className="grid grid-cols-1">
                    <div>
                               <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow-md dark:shadow-gray-700">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-search absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <input name="name" type="text" id="job-keyword" className="form-input lg:rounded-t-sm lg:rounded-e-none lg:rounded-b-none lg:rounded-s-sm lg:outline-0 w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-0" placeholder="Search your Keywords" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-estate absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={project} />

                                        </div>
                                    </div>

                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-usd-circle absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={minPrice} />

                                        </div>
                                    </div>

                                    <div>
                                        <div className="filter-search-form relative lg:before:content-[''] lg:before:absolute lg:before:top-[10px] lg:before:end-0 lg:before:h-10 lg:before:z-1 lg:before:border-r lg:before:border-inherit lg:before:rounded-md lg:before:outline-0 lg:dark:before:border-gray-700">
                                            <i className="uil uil-usd-circle absolute top-[48%] -translate-y-1/2 start-3 z-1 text-indigo-600 text-[20px]"></i>
                                            <Select className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={maxPrice} />

                                        </div>
                                    </div>

                                    <div className="lg:mt-6">
                                        <input type="submit" id="search-buy" name="search" className="btn bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white searchbtn submit-btn w-full !h-12 rounded" value="Search" />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <section className="relative lg:py-24 py-16">
                <div className="container relative">
                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">

                        {listing.map((data,index) => {
                            return (
                                <div key={index} className="group rounded-md bg-white dark:bg-slate-900 shadow hover:shadow-xl dark:hover:shadow-xl dark:shadow-gray-800 dark:hover:shadow-gray-700 overflow-hidden ease-in-out duration-500">
                                    <div className="relative">
                                        <img src={data.image} alt="" />

                                        <div className="absolute top-6 end-6">
                                            <Link className="h-9 w-9 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-lg text-center bg-white dark:bg-slate-900 border-0 shadow dark:shadow-gray-800 rounded-full text-red-600"><i className="mdi mdi-heart"></i></Link>
                                        </div>
                                    </div>

                                    <div className="p-6">
                                        <div className="pb-6">
                                            <Link to="/property-detail" className="text-lg hover:text-indigo-600 font-medium ease-in-out duration-500">{data.address}</Link>
                                        </div>

                                        <ul className="py-6 border-y border-gray-100 dark:border-gray-800 flex items-center list-none">
                                            <li className="flex items-center me-4">
                                                <i className="uil uil-compress-arrows text-2xl me-2 text-indigo-600"></i>
                                                <span>{data.sqrt}</span>
                                            </li>

                                            <li className="flex items-center me-4">
                                                <i className="uil uil-bed-double text-2xl me-2 text-indigo-600"></i>
                                                <span>{data.beds}</span>
                                            </li>

                                            <li className="flex items-center">
                                                <i className="uil uil-bath text-2xl me-2 text-indigo-600"></i>
                                                <span>{data.bath}</span>
                                            </li>
                                        </ul>

                                        <ul className="pt-6 flex justify-between items-center list-none">
                                            <li>
                                                <span className="text-slate-400">Price</span>
                                                <p className="text-lg font-medium">$5000</p>
                                            </li>

                                            <li>
                                                <span className="text-slate-400">Rating</span>
                                                <ul className="text-lg font-medium text-amber-400 list-none">
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline"><i className="mdi mdi-star"></i></li>
                                                    <li className="inline text-black dark:text-white">5.0(30)</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )
                        })}
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
                        <div className="md:col-span-12 text-center">
                            <nav>
                                <ul className="inline-flex items-center -space-x-px">
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                            <i className="uil uil-angle-left text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">1</Link>
                                    </li>
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">2</Link>
                                    </li>
                                    <li>
                                        <Link to="/#" aria-current="page" className="z-10 w-[40px] h-[40px] inline-flex justify-center items-center text-white bg-indigo-600 border border-indigo-600">3</Link>
                                    </li>
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">4</Link>
                                    </li>
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 hover:text-white bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">5</Link>
                                    </li>
                                    <li>
                                        <Link to="/#" className="w-[40px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-lg hover:text-white border border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600">
                                            <i className="uil uil-angle-right text-[20px] rtl:rotate-180 rtl:-mt-1"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
                <div className="container relative">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                    <div className="lg:col-span-4 md:col-span-12">
                                        <Link to="/#" className="text-[22px] focus:outline-none">
                                            <img src={logo_light} alt="" />
                                        </Link>
                                        <p className="mt-6 text-gray-300">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                        <ul className="list-none space-x-1 mt-6 flex">
                                            <li><Link to="#" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-shopping-cart align-middle" title="Buy Now"></i></Link></li>
                                            <li><Link to="/https://dribbble.com/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-dribbble align-middle" title="dribbble"></i></Link></li>
                                            <li><Link to="/https://www.behance.net/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-behance" title="Behance"></i></Link></li>
                                            <li><Link to="/http://linkedin.com/company/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                            <li><Link to="/https://www.facebook.com/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                                            <li><Link to="/https://www.instagram.com/shreethemes/" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-instagram align-middle" title="instagram"></i></Link></li>
                                            <li><Link to="/https://twitter.com/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-twitter align-middle" title="twitter"></i></Link></li>
                                            <li><Link to="/mailto:support@shreethemes.in" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-envelope align-middle" title="email"></i></Link></li>
                                            <li><Link to="/https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><i className="uil uil-file align-middle" title="customization"></i></Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-2 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Company</h5>
                                        <ul className="list-none space-x-1 footer-list mt-6">
                                            <li><Link to="/page-aboutus" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> About us</Link></li>
                                            <li className="mt-[10px]"><Link to="/page-services" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Services</Link></li>
                                            <li className="mt-[10px]"><Link to="/page-team" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Team</Link></li>
                                            <li className="mt-[10px]"><Link to="/page-pricing" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Pricing</Link></li>
                                            <li className="mt-[10px]"><Link to="/portfolio-creative-four" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Project</Link></li>
                                            <li className="mt-[10px]"><Link to="/blog" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Blog</Link></li>
                                            <li className="mt-[10px]"><Link to="/auth-login" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Login</Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-3 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Usefull Links</h5>
                                        <ul className="list-none space-x-1 footer-list mt-6">
                                            <li><Link to="/page-terms" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Terms of Services</Link></li>
                                            <li className="mt-[10px]"><Link to="/page-privacy" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Privacy Policy</Link></li>
                                            <li className="mt-[10px]"><Link to="/documentation" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Documentation</Link></li>
                                            <li className="mt-[10px]"><Link to="/changelog" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Changelog</Link></li>
                                            <li className="mt-[10px]"><Link to="#!" className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out"><i className="uil uil-angle-right-b"></i> Widget</Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-3 space-x-1 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Newsletter</h5>
                                        <p className="mt-6">Sign up and receive the latest tips via email.</p>
                                        <form>
                                            <div className="grid grid-cols-1">
                                                <div className="foot-subscribe my-3">
                                                    <label className="form-label">Write your email <span className="text-red-600">*</span></label>
                                                    <div className="form-icon relative mt-2">
                                                        <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                                                        <input type="email" className="form-input ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0" placeholder="Email" name="email" required="" />
                                                    </div>
                                                </div>

                                                <button type="submit" id="submitsubscribe" name="send" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Subscribe</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="container relative text-center">
                        <div className="grid md:grid-cols-2 items-center">
                            <div className="md:text-start text-center">
                                <p className="mb-0">© {new Date().getFullYear()} cloudgia. Design & Develop with <i className="mdi mdi-heart text-red-600"></i> by <Link to="" target="_blank" className="text-reset">JR</Link>.</p>
                            </div>

                            <ul className="list-none space-x-1 md:text-end text-center mt-6 md:mt-0 flex justify-end">
                                <li><Link ><img src={american_ex} className="max-h-6 inline" title="American Express" alt="" /></Link></li>
                                <li><Link ><img src={discover} className="max-h-6 inline" title="Discover" alt="" /></Link></li>
                                <li><Link ><img src={master_card} className="max-h-6 inline" title="Master Card" alt="" /></Link></li>
                                <li><Link ><img src={paypal} className="max-h-6 inline" title="Paypal" alt="" /></Link></li>
                                <li><Link ><img src={visa} className="max-h-6 inline" title="Visa" alt="" /></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
