import React, {useState} from 'react'
import { Link } from 'react-router-dom';

import logo_icon_40 from '../../assets/images/logo-icon-40.png';
import about_2 from '../../assets/images/hospital/about-2.png';
import imageP from '../../assets/images/client/01.jpg';
import image1 from '../../assets/images/client/03.jpg';
import image2 from '../../assets/images/client/02.jpg';
import image3 from '../../assets/images/client/05.jpg';
import image4 from '../../assets/images/client/04.jpg';
import image5 from '../../assets/images/client/06.jpg';
import image6 from '../../assets/images/client/05.jpg';
import image7 from '../../assets/images/client/06.jpg';
import image8 from '../../assets/images/client/06.jpg';
import image9 from '../../assets/images/client/03.jpg';
import image10 from '../../assets/images/client/04.jpg';
import image11 from '../../assets/images/client/05.jpg';
import image12 from '../../assets/images/client/03.jpg';
import image13 from '../../assets/images/client/04.jpg';
import image14 from '../../assets/images/client/05.jpg';
import image15 from '../../assets/images/client/04.jpg';
import image16 from '../../assets/images/client/02.jpg';
import image17 from '../../assets/images/client/03.jpg';
import image18 from '../../assets/images/client/04.jpg';
import bg from '../../assets/images/hospital/bg.jpg';
import image24 from '../../assets/images/blog/01.jpg';
import image25 from '../../assets/images/blog/02.jpg';
import image26 from '../../assets/images/blog/03.jpg';
import amazon from '../../assets/images/client/amazon.svg';
import google from '../../assets/images/client/google.svg';
import lenovo from '../../assets/images/client/lenovo.svg';
import paypal from '../../assets/images/client/paypal.svg';
import shopify from '../../assets/images/client/shopify.svg';
import spotify from '../../assets/images/client/spotify.svg';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';
import UserFeedBack2 from '../../component/userFeedBack2';
import CookieModal from '../../component/cookieModal';


import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/css/modal-video.css";
import CountUp from 'react-countup';
import { Facebook, Instagram, Linkedin, Twitter } from 'feather-icons-react/build/IconComponents';

export default function IndexHospital() {
    const [isOpen, setOpen] = useState(false)

    const ServicesData = [
        {
            title:"Eye Care",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"eye",
        },
        {
            title:"Psychotherapy",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"syringe",
        },
        {
            title:"Primary Care",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"thermometer",
        },
        {
            title:"Dental Care",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"tablets",
        },
        {
            title:"Orthopedic",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"microscope",
        },
        {
            title:"Cardiology",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"heart-rate",
        },
        {
            title:"Gynecology",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"hospital",
        },
        {
            title:"Neurology",
            desc:"The phrasal sequence of the Lorem Ipsum text is now so that many",
            icone:"band-aid",
        },
    ]
    const teamData = [
        {
            image:imageP,
            name:"Ronny Jofra",
            title:'Eye Care Specialist',
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image16,
            name:"Aliana Rosy",
            title:'Gynecologist',
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image17,
            name:"Sofia Razaq",
            title:'Psychologist',
            desc:"If the distribution of letters and 'words' is random"
        },
        {
            image:image18,
            name:"Micheal Carlo",
            title:'Orthopedic',
            desc:"If the distribution of letters and 'words' is random"
        },
    ]
    const blogData = [
        {
            image:image24,
            title:"You can easily connect to doctor and make a treatment",
            desc:"The phrasal sequence of the is now so that many campaign and benefit",
        },
        {
            image:image25,
            title:"Lockdowns lead to fewer people seeking medical care",
            desc:"The phrasal sequence of the is now so that many campaign and benefit",
        },
        {
            image:image26,
            title:"Emergency medicine research course for the doctors",
            desc:"The phrasal sequence of the is now so that many campaign and benefit",
        },
    ]
    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative table w-full py-36 lg:py-64 bg-[url('../../assets/images/hospital/bg.jpg')] bg-no-repeat bg-center bg-cover">
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 mt-10">
                        <img src={logo_icon_40} alt="" />

                        <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6 mt-3">Meet The <br /> Best Doctor</h1>
                        <p className="text-white/60 text-lg max-w-xl">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>

                        <div className="mt-8">
                            <Link to="#" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Make Appointment</Link>

                            <p className="text-white/60 text-base mt-2">T&C apply. Please read <Link  className="text-white">Terms and Conditions <i className="uil uil-arrow-right"></i></Link></p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="flex justify-center">
                        <div className="relative z-2 transition-all duration-500 ease-in-out sm:-mt-[200px] -mt-[140px] m-0 lg:w-10/12 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md p-6">
                            <div className="grid md:grid-cols-3 grid-cols-1 gap-[24px]">
                                <div>
                                    <div
                                        className="w-16 h-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800">
                                        <i className="uil uil-clinic-medical"></i>
                                    </div>

                                    <div className="content mt-6">
                                        <h5 className="text-lg font-medium">Emergency Cases</h5>
                                        <p className="text-slate-400 mt-3">This is required when, for example, the is not yet available. Dummy text is also known as 'fill text'.</p>

                                        <div className="mt-5">
                                            <Link 
                                                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read
                                                More <i className="uil uil-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className="w-16 h-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800">
                                        <i className="uil uil-medkit"></i>
                                    </div>

                                    <div className="content mt-6">
                                        <h5 className="text-lg font-medium">Doctors Timetable</h5>
                                        <p className="text-slate-400 mt-3">This is required when, for example, the is not yet available. Dummy text is also known as 'fill text'.</p>

                                        <div className="mt-5">
                                            <Link 
                                                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read
                                                More <i className="uil uil-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div
                                        className="w-16 h-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800">
                                        <i className="uil uil-clock"></i>
                                    </div>

                                    <div className="content mt-6">
                                        <h5 className="text-lg font-medium">Opening Hours</h5>
                                        <ul className="list-none mt-3 space-x-1">
                                            <li className="flex justify-between">
                                                <p className="text-slate-400">Monday - Friday</p>
                                                <p className="text-indigo-600">8.00 - 20.00</p>
                                            </li>
                                            <li className="flex justify-between">
                                                <p className="text-slate-400">Saturday</p>
                                                <p className="text-indigo-600">8.00 - 18.00</p>
                                            </li>
                                            <li className="flex justify-between">
                                                <p className="text-slate-400">Sunday</p>
                                                <p className="text-indigo-600">8.00 - 14.00</p>
                                            </li>
                                        </ul>

                                        <div className="mt-5">
                                            <Link 
                                                className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read
                                                More <i className="uil uil-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src={about_2} className="rounded-md" alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#" onClick={() => setOpen(true)} data-type="youtube" data-id="S_CGed6E610"
                                        className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-xl"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

                        <div className="md:col-span-7">
                            <div className="lg:ms-4">
                                <h4 className="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">About Our Treatments</h4>
                                <p className="text-slate-400 max-w-xl">Great doctor if you need your family member to get effective immediate assistance, examination, emergency treatment or a simple consultation. Thank you.</p>
                                <p className="text-slate-400 max-w-xl mt-3">The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. Lorem Ipsum is composed in a pseudo-Latin language which more or less corresponds to 'proper' Latin. It contains a series of real Latin words.</p>
                                <Link  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3">Read More <i className="mdi mdi-chevron-right align-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Medical Services</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 mt-8 gap-[30px]">
                        {ServicesData.map((item,index)=>{
                            return(
                                <div className="text-center md:px-6" key={index}>
                                    <div className="w-20 h-20 bg-indigo-600/5 text-indigo-600 rounded-3xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                        <i className={`uil uil-${item.icone}`}></i>
                                    </div>
        
                                    <div className="content mt-7">
                                        <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">{item.title}</Link>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 md:pb-0 pb-0 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Doctors Time Table</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                    </div>
                </div>

                <div className="container-fluid relative">
                    <div className="grid grid-cols-1 mt-8">
                        <div className="relative overflow-x-auto block w-full bg-white dark:bg-slate-900">
                            <table className="w-full text-sm text-start">
                                <thead
                                    className="text-lg">
                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[120px]">Time Table</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Monday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Tuesday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Wednesday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Thursday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Friday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Saturday</th>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-6 min-w-[200px]">Sunday</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">09:00AM</th>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={imageP} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Calvin Carlo</h6>
                                                    <span className="text-slate-400">Eye Care</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00AM - 10:00AM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image1} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Alia Reddy</h6>
                                                    <span className="text-slate-400">Psychotherapy</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00AM - 01:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                    </tr>

                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">11:00AM</th>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image2} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Cristino Murphy</h6>
                                                    <span className="text-slate-400">Gynecology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">11:00AM - 04:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image3} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Jennifer Ballance</h6>
                                                    <span className="text-slate-400">Cardiology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">11:00AM - 12:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image4} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Toni Kovar</h6>
                                                    <span className="text-slate-400">Orthopedic</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00AM - 10:00AM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                    </tr>

                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">02:00PM</th>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image5} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Tara Arrington</h6>
                                                    <span className="text-slate-400">Neurology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">02:00PM - 04:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image6} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Jennifer Ballance</h6>
                                                    <span className="text-slate-400">Cardiology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">11:00AM - 12:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                    </tr>

                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">04:00PM</th>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image7} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Tara Arrington</h6>
                                                    <span className="text-slate-400">Neurology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">04:00PM - 05:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image8} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Tara Arrington</h6>
                                                    <span className="text-slate-400">Neurology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">04:30PM - 06:00PM</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">06:00PM</th>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image9} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Alia Reddy</h6>
                                                    <span className="text-slate-400">Psychotherapy</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">06:00PM - 09:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image10} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Toni Kovar</h6>
                                                    <span className="text-slate-400">Orthopedic</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">07:00PM - 08:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image11} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Jennifer Ballance</h6>
                                                    <span className="text-slate-400">Cardiology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">06:00PM - 07:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image12} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Alia Reddy</h6>
                                                    <span className="text-slate-400">Psychotherapy</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">06:00PM - 07:00PM</span>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="text-center border border-gray-100 dark:border-gray-700 py-5">09:00PM</th>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image13} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Toni Kovar</h6>
                                                    <span className="text-slate-400">Orthopedic</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00PM - 10:00PM</span>
                                        </td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image14} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Jennifer Ballance</h6>
                                                    <span className="text-slate-400">Cardiology</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00PM - 10:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="p-3 border border-gray-100 dark:border-gray-700">
                                            <div className="flex items-center mb-3">
                                                <img src={image15} className="h-12 w-12 rounded-full shadow" alt="" />
                                                <div className="ms-3">
                                                    <h6 className="block font-medium text-lg">Toni Kovar</h6>
                                                    <span className="text-slate-400">Orthopedic</span>
                                                </div>
                                            </div>
                                            <span className="py-2 px-4 bg-indigo-600/5 text-indigo-600 rounded-md block text-center">09:00PM - 10:00PM</span>
                                        </td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                        <td className="border border-gray-100 dark:border-gray-700"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Doctors Team</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                        {teamData.map((item,index)=>{
                            return(
                                <div className="lg:col-span-3 md:col-span-6" key={index}>
                                <div className="team p-6 rounded-md border border-gray-100 dark:border-gray-700 group bg-white dark:bg-slate-900">
                                    <img src={item.image} className="h-24 w-24 rounded-full shadow-md dark:shadow-gray-800" alt="" />
    
                                    <div className="content mt-4">
                                        <Link  className="text-lg font-medium hover:text-indigo-600 block">{item.name}</Link>
                                        <span className="text-slate-400 block">{item.title}</span>
    
                                        <p className="text-slate-400 mt-4">{item.desc}</p>
    
                                        <ul className="list-none  space-x-1 mt-4 flex">
                                            <li><Link  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><Facebook className="h-4 w-4"></Facebook></Link></li>
                                            <li><Link  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><Instagram className="h-4 w-4"></Instagram></Link></li>
                                            <li><Link  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><Twitter className="h-4 w-4"></Twitter></Link></li>
                                            <li><Link  className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle transition duration-500 ease-in-out text-base text-center border border-gray-100 dark:border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 hover:text-white"><Linkedin className="h-4 w-4"></Linkedin></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 md:pt-0 pt-0">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div className="lg:col-start-2 lg:col-span-10">
                                    <div className="relative">
                                        <img src={bg} className="rounded-md shadow-lg" alt="" />
                                        <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                            <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610"
                                                className="lightbox h-20 w-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content md:mt-8">
                                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-3 grid-cols-1 items-center">

                                            <div className="counter-box text-center">
                                                <h1 className="text-white text-4xl font-semibold mb-2"><CountUp className="counter-value" end={99} start={10} />%</h1>
                                                <h5 className="counter-head text-white text-lg font-semibold mb-2">Positive feedback</h5>
                                                <p className="text-white/50">From Doctors</p>
                                            </div>


                                            <div className="counter-box text-center">
                                                <h1 className="text-white text-4xl font-semibold mb-2"><CountUp className="counter-value" end={25} start={2} />+</h1>
                                                <h5 className="counter-head text-white text-lg font-semibold mb-2">Experienced Clinics</h5>
                                                <p className="text-white/50">High Qualified</p>
                                            </div>


                                            <div className="counter-box text-center">
                                                <h1 className="text-white text-4xl font-semibold mb-2"><CountUp className="counter-value" end={1251} start={95} />+</h1>
                                                <h5 className="counter-head text-white text-lg font-semibold mb-2">Questions & Answers</h5>
                                                <p className="text-white/50">Your Questions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-indigo-500 to-indigo-600"></div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Patients Says</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Great doctor if you need your family member to get effective immediate assistance, emergency treatment or a simple consultation.</p>
                    </div>

                    <UserFeedBack2 />
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Latest News & Blogs</h3>
                        <p className="text-slate-400 max-w-xl mx-auto"></p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        {blogData.map((item,index)=>{
                            return(
                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden" key={index}>
                                    <img src={item.image} alt="" />

                                    <div className="content p-6">
                                        <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">{item.title}</Link>
                                        <p className="text-slate-400 mt-3">{item.desc}</p>

                                        <div className="mt-4">
                                            <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="py-6 bg-gray-50 dark:bg-slate-800">
                <div className="container relative">
                    <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-[30px]">
                        <div className="mx-auto py-4">
                            <img src={amazon} className="h-6" alt="" />
                        </div>

                        <div className="mx-auto py-4">
                            <img src={google} className="h-6" alt="" />
                        </div>

                        <div className="mx-auto py-4">
                            <img src={lenovo} className="h-6" alt="" />
                        </div>

                        <div className="mx-auto py-4">
                            <img src={paypal} className="h-6" alt="" />
                        </div>

                        <div className="mx-auto py-4">
                            <img src={shopify} className="h-6" alt="" />
                        </div>

                        <div className="mx-auto py-4">
                            <img src={spotify} className="h-6" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
      <CookieModal/>
        </>
    )
}
