import React, { useState } from 'react'

import imageP from '../../assets/images/client/05.jpg';
import image1 from '../../assets/images/blog/01.jpg';
import image2 from '../../assets/images/blog/09.jpg';
import image3 from '../../assets/images/blog/10.jpg';
import image4 from '../../assets/images/blog/11.jpg';
import image5 from '../../assets/images/blog/12.jpg';
import image6 from '../../assets/images/food/blog/1.jpg';
import image7 from '../../assets/images/blog/13.jpg';
import image8 from '../../assets/images/blog/14.jpg';
import image9 from '../../assets/images/food/blog/2.jpg';
import image10 from '../../assets/images/blog/02.jpg';
import image11 from '../../assets/images/blog/03.jpg';
import image12 from '../../assets/images/blog/04.jpg';
import image13 from '../../assets/images/blog/05.jpg';
import image14 from '../../assets/images/blog/06.jpg';
import image15 from '../../assets/images/blog/07.jpg';
import image16 from '../../assets/images/blog/08.jpg';
import image17 from '../../assets/images/blog/06.jpg';
import image18 from '../../assets/images/blog/07.jpg';
import image19 from '../../assets/images/blog/08.jpg';
import image20 from '../../assets/images/blog/01.jpg';
import image21 from '../../assets/images/blog/02.jpg';
import image22 from '../../assets/images/blog/03.jpg';
import image23 from '../../assets/images/blog/04.jpg';
import image24 from '../../assets/images/blog/05.jpg';
import image25 from '../../assets/images/blog/06.jpg';
import image26 from '../../assets/images/food/blog/1.jpg';
import image27 from '../../assets/images/food/blog/2.jpg';
import image28 from '../../assets/images/food/blog/3.jpg';
import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';


import { Facebook, Github, Gitlab, Instagram, Linkedin, Twitter, Youtube } from 'feather-icons-react/build/IconComponents';
import { Link } from 'react-router-dom';
import CookieModal from '../../component/cookieModal';
import Masonry from "react-responsive-masonry";

export default function IndexBlog() {
    const [selectedCategory, setSelectedCategory] = useState(null);

    const data = [
        {
            id:1,
            image:image1,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'business',
        },
        {
            id:2,
            image:image2,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'tech',
        },
        {
            id:3,
            image:image3,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'tech',
        }, 
         {
            id:4,
            image:image4,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'tech',
        },
        {
            id:5,
            image:image5,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'lifestyle',
        },
        {
            id:6,
            image:image6,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'food',
        },
        {
            id:7,
            image:image7,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'lifestyle',
        },
        {
            id:8,
            image:image8,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'business',
        },
        {
            id:9,
            image:image9,
            title:'Design your apps in your own way',
            desc:'The phrasal sequence of the is now so that many campaign and benefit',
            category:'food',
        },
    ]
    const matchCategory = (category) => {
        setSelectedCategory(category);
    };

    const filteredData = selectedCategory
        ? data.filter((item) => item.category === selectedCategory)
        : data;
    return (
        <>
            <Navbar navClass="nav-light" />

            <section className="relative md:pt-60 pt-40 md:pb-64 pb-56 flex items-center bg-[url('../../assets/images/blog/bg1.jpg')] bg-center bg-no-repeat bg-cover jarallax" data-jarallax data-speed="0.5">
                <div className="absolute inset-0 bg-slate-900/40"></div>
                <div className="container relative">
                    <div className="md:flex justify-center mt-10">
                        <div className="w-full">
                            <span className="flex items-center">
                                <img src={imageP} className="h-8 rounded-full shadow dark:shadow-gray-800" alt="" />

                                <span className="ms-1 text-lg"><span className="text-white/80 mx-1">by</span> <Link className="text-white">Cristino Loly</Link></span>
                                <span className="ms-1 text-lg"><span className="text-white/80 mx-1">in</span> <Link className="text-white">Business</Link></span>
                            </span>

                            <Link className="font-bold text-white leading-snug text-[30px] lg:text-[42px] mt-6 block">Establishing that <br /> you have something to say</Link>

                            <div className="mt-8">
                                <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">Read Article <i className="uil uil-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-2 transition-all duration-500 ease-in-out sm:-mt-[220px] -mt-[200px] m-0">
                            <div className="filters-group-wrap">
                                <div className="filters-group">
                                    <ul className="mb-0 list-none container-filter-white filter-options text-center">
                                        <li className={`${selectedCategory === null ? 'active' : ''} inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-white/70 transition duration-500`} data-group="all" onClick={() => matchCategory(null)}>All</li>
                                        <li className={`${selectedCategory === 'business' ? 'active' : ''} inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-white/70 transition duration-500`} data-group="business" onClick={() => matchCategory('business')}>Business</li>
                                        <li className={`${selectedCategory === 'tech' ? 'active' : ''} inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-white/70 transition duration-500`} data-group="tech" onClick={() => matchCategory('tech')}>Technology</li>
                                        <li className={`${selectedCategory === 'lifestyle' ? 'active' : ''} inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-white/70 transition duration-500`} data-group="lifestyle" onClick={() => matchCategory('lifestyle')}>Lifestyle</li>
                                        <li className={`${selectedCategory === 'food' ? 'active' : ''} inline-block text-lg font-semibold mx-2 mb-3 cursor-pointer relative border-b border-transparent text-white/70 transition duration-500`} data-group="food" onClick={() => matchCategory('food')}>Food</li>
                                    </ul>
                                </div>
                            </div>

                            <Masonry columnsCount={3}>
                                {filteredData.map((item,index)=>{
                                    return(
                                        <div className="p-3 picture-item" data-groups='["business"]' key={index}>
                                        <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                            <img src={item.image} alt="" />
    
                                            <div className="content p-6">
                                                <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">{item.title}</Link>
                                                <p className="text-slate-400 mt-3">{item.desc}</p>
    
                                                <div className="mt-4">
                                                    <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </Masonry>
                        </div>
                    </div>
                </div>

                <div className="container relative mt-16">
                    <div className="relative bg-white dark:bg-slate-900 lg:px-8 px-6 py-10 rounded-xl shadow dark:shadow-gray-800 overflow-hidden">
                        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-[30px]">
                            <div className="md:text-start text-center z-1">
                                <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">Subscribe to Newsletter!</h3>
                                <p className="text-slate-400 max-w-xl mx-auto mt-2">Subscribe to get latest updates and information.</p>
                            </div>

                            <div className="subcribe-form z-1">
                                <form className="relative max-w-xl">
                                    <input type="email" id="subcribe" name="email" className="pt-4 pe-40 pb-4 ps-6 w-full h-[50px] outline-none text-black dark:text-white rounded-full bg-white dark:bg-slate-900 shadow dark:shadow-gray-800" placeholder="Your Email Address :" />
                                    <button type="submit" className="py-2 px-5 inline-block font-semibold tracking-wide align-middle transition duration-500 ease-in-out text-base text-center absolute top-[2px] end-[3px] h-[46px] bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white rounded-full">Subscribe</button>
                                </form>
                            </div>
                        </div>

                        <div className="absolute -top-5 -start-5">
                            <div className="uil uil-envelope lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5 -rotate-45"></div>
                        </div>

                        <div className="absolute -bottom-5 -end-5">
                            <div className="uil uil-pen lg:text-[150px] text-7xl text-slate-900/5 dark:text-white/5"></div>
                        </div>
                    </div>
                </div>

                <div className="container relative mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 pb-8 items-end">
                        <div className="lg:col-span-8 md:col-span-6 md:text-start text-center">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Featured News Post</h3>
                            <p className="text-slate-400 max-w-xl">Search your future opportunity with our categories</p>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6 md:text-end hidden md:block">
                            <Link className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:transition-all after:duration-500 text-slate-400 hover:text-indigo-600 after:bg-indigo-600 duration-500 ease-in-out">All Categories <i className="uil uil-arrow-right align-middle"></i></Link>
                        </div>
                    </div>

                    <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="grid grid-cols-1 gap-[30px]">
                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image10} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Take a break through the countryside</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image11} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">These 10 sea destinations are surprises</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image12} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">A photographer perfect day in Colorado</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image13} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">This is the world most beautiful beach</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image14} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Travel will change you for the better</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image15} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">Greek beyond Athene: Where to go next</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden">
                                    <div className="lg:flex relative">
                                        <div className="relative md:shrink-0">
                                            <img className="h-full w-full object-cover lg:w-52 lg:h-56" src={image16} alt="" />
                                        </div>
                                        <div className="p-6 flex flex-col lg:h-56 justify-center">
                                            <Link to="/blog-detail" className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">This mountain will make you reborn</Link>
                                            <div className="my-auto">
                                                <p className="text-slate-400 mt-3">The phrasal sequence of the is now so that many campaign and benefit</p>
                                            </div>

                                            <div className="mt-4">
                                                <Link to="/blog-detail" className="relative inline-block font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">Read More <i className="uil uil-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="sticky top-20">
                                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Recent post</h5>
                                <div className="flex items-center mt-8">
                                    <img src={image17} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                    <div className="ms-3">
                                        <Link className="font-semibold hover:text-indigo-600">Consultant Business</Link>
                                        <p className="text-sm text-slate-400">1st May 2022</p>
                                    </div>
                                </div>

                                <div className="flex items-center mt-4">
                                    <img src={image18} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                    <div className="ms-3">
                                        <Link className="font-semibold hover:text-indigo-600">Grow Your Business</Link>
                                        <p className="text-sm text-slate-400">1st May 2022</p>
                                    </div>
                                </div>

                                <div className="flex items-center mt-4">
                                    <img src={image19} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                                    <div className="ms-3">
                                        <Link className="font-semibold hover:text-indigo-600">Look On The Glorious Balance</Link>
                                        <p className="text-sm text-slate-400">1st May 2022</p>
                                    </div>
                                </div>

                                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Social sites</h5>
                                <ul className="list-none text-center mt-8 space-x-1">
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Facebook className="h-4 w-4"></Facebook></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Instagram className="h-4 w-4"></Instagram></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Twitter className="h-4 w-4"></Twitter></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Linkedin className="h-4 w-4"></Linkedin></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Github className="h-4 w-4"></Github></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Youtube className="h-4 w-4"></Youtube></Link></li>
                                    <li className="inline"><Link className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Gitlab className="h-4 w-4"></Gitlab></Link></li>
                                </ul>

                                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Tagscloud</h5>
                                <ul className="list-none text-center mt-8 space-x-1">
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Business</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Finance</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Marketing</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Fashion</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Bride</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Lifestyle</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Travel</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Beauty</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Video</Link></li>
                                    <li className="inline-block m-2"><Link className="px-3 py-1 text-slate-400 hover:text-white dark:hover:text-white bg-gray-50 dark:bg-slate-800 text-sm hover:bg-indigo-600 dark:hover:bg-indigo-600 rounded-md shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out">Audio</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Recent Posts</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>

                    <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <div className="flex items-center">
                            <img src={image20} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Consultant Business</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image21} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Grow Your Business</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image22} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Look On The Glorious Balance</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image23} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">5 incredible city to travel in Italy</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image24} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">25 essentials for your travel backpack</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image25} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Amazing destination in Portugal</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image26} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Free solo travel across North Europe</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image27} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Explorer completes historic lakes</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <img src={image28} className="lg:h-20 h-16 rounded-md shadow dark:shadow-gray-800" alt="" />

                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">Dont miss these amazing side trips</Link>
                                <p className="text-sm text-slate-400 mt-1">1st May 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>



            <Footer />

            <CookieModal />
       



         


           

        </>
    )
}
